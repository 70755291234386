<template>
  <div class="users-home-container">
    <b-overlay :show="userBusy">
      <paginate :meta="usersMeta" :place-holder="$t('PAGES.USERS.HOME.LABEL.SEARCH_PLACEHOLDER')" @change-page="onChangePage" @refresh="onRefresh" />
      <b-row>
        <b-col sm="6" md="3" v-for="(user, index) in users" :key="user.id">
          <portlet title=" " body-fluid body-class="px-3 pb-3 pt-0" headNoBorder head-class="px-2" head-size="sm">
            <template slot="toolbar">
              <a v-if="user.lock_logs.length" v-b-tooltip.hover="{customClass: 'custom-tooltip'}" :title="`${$t('PAGES.USERS.HOME.LABEL.BLACK_LIST_REASON')}${ user.lock_logs[0].reason ? user.lock_logs[0].reason : $t('PAGES.USERS.HOME.LABEL.BLACK_LIST_NO_REASON') } ${!user.is_locked ? $t('PAGES.USERS.HOME.LABEL.IS_NOT_BLACK') : ''}`" class="text-decoration-none border-0 btn btn-clean btn-sm btn-icon btn-icon-md">
                <i class="fa fa-info-circle"></i>
              </a>
              <b-dropdown v-b-tooltip :title="$t('PAGES.USERS.HOME.LABEL.ACTIONS_TITLE')" toggle-class="text-decoration-none border-0 btn btn-clean btn-sm btn-icon btn-icon-md" no-caret>
                <template v-slot:button-content>
                  <i class="fa fa-ellipsis-v"></i>
                </template>
                <b-dropdown-divider />
                <b-dropdown-item @click="onClickSwitchLocked(user, index, $event)">
                  <i class="fa fa-user-lock"></i> {{ $t('PAGES.USERS.HOME.LABEL.BUTTON.SET_BLACK_LIST') }}
                </b-dropdown-item>
                <b-dropdown-item :to="{name: 'user.donations', params: {id: user.id}}">
                  <i class="fa fa-wallet"></i> {{ $t('PAGES.USERS.HOME.LABEL.BUTTON.DONATION_HISTORY') }}
                </b-dropdown-item>
                <b-dropdown-item @click="onClickUserAccount(user)">
                  <i class="fa fa-id-card"></i>{{ $t('PAGES.USERS.HOME.LABEL.BUTTON.SOCIALITE_ACCOUNTS') }}
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template slot="body">
              <div class="kt-widget-item-container">
                <div class="kt-widget kt-widget--user-profile-2">
                  <div class="kt-widget__head">
                    <div class="kt-widget__media">
                      <b-avatar size="50px" text="!" :src="user.avatar" />
                    </div>
                    <div class="kt-widget__info text-truncate">
                      <a class="kt-widget__username">
                         {{ user.nickname ? user.nickname : $t('COMMON.NO_BODY') }}
                      </a>
                    </div>
                  </div>
                  <div class="kt-widget__body">
                    <div class="kt-widget__item">
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">{{ $t('PAGES.USERS.HOME.LABEL.id') }}</span>
                        <span class="kt-widget__data">{{ user.id }}</span>
                      </div>
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">{{ $t('PAGES.USERS.HOME.LABEL.created_at') }}</span>
                        <span class="kt-widget__data direction-ltr">{{ user.created_at }}</span>
                      </div>
                      <div class="kt-widget__contact">
                        <span class="kt-widget__label">{{ $t('PAGES.USERS.HOME.LABEL.donation_total_fee') }}</span>
                        <span class="kt-widget__data direction-ltr">{{ user.order_total_fee }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <no-result :visible="!users.length" background="warning" :title="$t('PAGES.USERS.HOME.TIPS.NODATA_TITLE')" :desc="$t('PAGES.USERS.HOME.TIPS.NODATA_DESC')" />
    </b-overlay>
    <user-switch-lock-modal v-model="switchLockedModalState" :busy="lockModalBusy" :lock-state="currentLockState" :user-id="currentUserId" @switching="onSwitching" @switched="onSwitched" @failed="onSwitchFailed" />
    <user-account-modal v-model="accountModalState" :info="currentUser" />
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import paginate from "@v@/components/paginate";
  import NoResult from "@v@/partials/content/NoResult";
  import userSwitchLockModal from "@v@/components/modals/user.switch.lock.modal";
  import userAccountModal from "@v@/components/modals/user.account.modal";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import userMixin from "@m@/user.mixin";
  import commonMethodMixin from "@m@/common/common.method.mixin";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import {SET_USER_ERROR} from "@/store/modules/user.module";
  export default {
    name: "index",
    components: { Portlet, paginate, userSwitchLockModal, NoResult, userAccountModal },
    mixins: [formBusyMixin, userMixin, commonMethodMixin],
    created() {
      this.getUsers(1, '');
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: this.$t('PAGES.USERS.HOME.BREAD_CRUMBS_TITLES.FIRST_TITLE'), route: {name: 'user.home'}});
      BreadCrumbs.addBreadCrumb({title: this.$t('PAGES.USERS.HOME.BREAD_CRUMBS_TITLES.CURRENT_TITLE')});
    },
    data(){
      return {
        userBusy: false,
        switchLockedModalState: false,
        currentLockState: false,
        currentUserId: null,
        vipModalState: null,
        lockModalBusy: false,
        rewardModalState: false,
        accountModalState: false,
        rewardOptions: {},
        page: 1,
        title: '',
        currentUser: {}
      }
    },
    methods: {
      onChangePage(e){
        let { title, page } = e;
        this.page = page;
        this.title = title;
        this.getUsers(page, title);
      },
      onRefresh(title){
        this.page = 1;
        this.title = title;
        this.getUsers(1, title);
      },
      onChangeSwitch(id, column, value){
        this.switchColumn({
          resource: 'users',
          id, column, mutationType: SET_USER_ERROR,
          begin: ()=>{ this.showBusy('userBusy') },
          after: ()=>{ this.hideBusy('userBusy') },
        });
      },
      onClickSwitchLocked(user, index, e){
        let { is_locked, id } = user;
        this.setCurrentLockState(is_locked);
        this.setCurrentUserId(id);
        this.toggleSwitchLockedModalState();
      },
      setCurrentLockState(currentLockState = 0){
        this.currentLockState = currentLockState;
      },
      setCurrentUserId(currentUserId = null){
        this.currentUserId = currentUserId;
      },
      toggleSwitchLockedModalState(){
        this.switchLockedModalState = ! this.switchLockedModalState;
      },
      onSwitching(){
        this.showBusy('lockModalBusy');
      },
      onSwitched(){
        this.hideBusy('lockModalBusy');
        this.toggleSwitchLockedModalState();
        let { page, title } = this;
        this.getUsers(page, title);
      },
      onSwitchFailed(){
        this.hideBusy('lockModalBusy');
      },
      onClickUserAccount(user){
        this.currentUser = user;
        this.$nextTick(()=>{
          this.accountModalState = true;
        })
      }
    }
  }
</script>

<style scoped>
  .locked-state {
    margin-top: 2px;
    font-size: 18px;
  }
</style>
