<template>
  <div class="account-container">
    <portlet fluid-height head-no-border :title="` `">
      <template v-slot:body>
        <!--begin::Widget -->
        <div class="kt-widget kt-widget--user-profile-2">
          <div class="kt-widget__head">
            <div class="kt-widget__media">
              <b-img class="kt-widget__pic" :class="info.avatar ? '' : 'kt-hidden'" :src="info.avatar" />
              <div class="kt-widget__pic kt-widget__pic--success kt-font-success kt-font-boldest " :class="info.avatar ? 'kt-hidden' : ''">!</div>
            </div>
            <div class="kt-widget__info">
              <a href="javascript:;" class="kt-widget__username cursor-default"> {{ info.nickname || $t('COMMON.NO_BODY') }} </a>
              <span class="kt-widget__desc">{{ socialiteDesc }}</span>
            </div>
          </div>
          <div class="kt-widget__body">
<!--            <div class="kt-widget__section">xxx</div>-->
            <div class="kt-widget__item">
              <div class="kt-widget__contact">
                <span class="kt-widget__label cursor-default"> {{ $t("COMPONENTS.ITEMS.USER.ACCOUNT.LABEL.created_at") }} </span>
                <a href="javascript:;" class="kt-widget__data cursor-default direction-rtl">{{ info.created_at || $t("COMPONENTS.ITEMS.USER.ACCOUNT.LABEL.no_data") }}</a>
              </div>
              <div class="kt-widget__contact" @click="onClickCopyOpenid">
                <span class="kt-widget__label cursor-default"> openid </span>
                <a href="javascript:;" class="kt-widget__data cursor-default direction-rtl">{{ info.openid || $t("COMPONENTS.ITEMS.USER.ACCOUNT.LABEL.no_data") }}</a>
              </div>
            </div>
          </div>
<!--          <div class="kt-widget__footer"></div>-->
        </div>
        <!--end::Widget -->
      </template>
    </portlet>
  </div>
</template>

<script>
  import Portlet from "@v@/partials/content/Portlet";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  export default {
    name: "Account",
    components: { Portlet },
    mixins: [ sweetAlertMixin ],
    props: {
      info: {
        type: Object,
        default: ()=>{ return {} }
      },
      socialiteDesc: {
        type: String
      }
    },
    methods: {
      onClickCopyOpenid(){
        let { openid } = this.info;
        if( openid ){
          this.$clipboard(openid);
          this.success({ message: this.$t("COMPONENTS.ITEMS.USER.ACCOUNT.LABEL.copy_openid_success"), title: this.socialiteDesc });
        }
      },
    }
  }
</script>

<style scoped>

</style>
