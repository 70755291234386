<template>
  <div class="user-list">
    <error :errors="userError" />
    <b-row>
      <b-col sm="12" md="3">
        <portlet>
          <template v-slot:body>
            <!--begin::Widget -->
            <div class="kt-widget kt-widget--user-profile-4">
              <div class="kt-widget__head">
                <div class="kt-widget__media">
                  <b-img class="kt-widget__img" :src="user.avatar" />
                </div>
                <div class="kt-widget__content">
                  <div class="kt-widget__section">
                    <a class="kt-widget__username cursor-default">
                      {{ user.nickname }}
                    </a>
                    <div class="kt-widget__button cursor-default" v-if="user.phone">
                      <span class="cursor-default btn btn-label-success btn-sm direction-ltr d-inline-flex"><i class="fa fa-phone-square-alt"></i> {{user.phone}}</span>
                    </div>
                    <div class="kt-widget__action" v-if="false">
                      <a href="#" class="btn btn-icon btn-circle btn-label-google">
                        <i class="socicon-google"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="kt-widget__body" v-if="false">
                <a href="#" class="kt-widget__item kt-widget__item--active-">
                  Profile Overview
                </a>
                <a href="#" class="kt-widget__item">
                  Personal info
                </a>
              </div>
            </div>
            <!--end::Widget -->
          </template>
        </portlet>
        <portlet>
          <template v-slot:body>
            <div class="kt-widget1 kt-widget1--fit">
              <div class="kt-widget1__item">
                <div class="kt-widget1__info">
                  <h3 class="kt-widget1__title">{{ $t('PAGES.USERS.DONATION.LABEL.order_total_fee') }}</h3>
                  <span class="kt-widget1__desc">{{ $t('PAGES.USERS.DONATION.LABEL.order_total_fee_desc') }}</span>
                </div>
                <span class="kt-widget1__number kt-font-brand">{{ user.order_total_fee }} <span>{{ $t("COMMON.$") }}</span></span>
              </div>
              <div class="kt-widget1__item">
                <div class="kt-widget1__info">
                  <h3 class="kt-widget1__title">{{ $t('PAGES.USERS.DONATION.LABEL.total_article') }}</h3>
                  <span class="kt-widget1__desc">{{ $t('PAGES.USERS.DONATION.LABEL.total_article_desc') }}</span>
                </div>
                <span class="kt-widget1__number kt-font-danger">{{ user.total_article }} <span>{{ $t('PAGES.USERS.DONATION.LABEL.total_article_limit') }}</span></span>
              </div>
              <div class="kt-widget1__item">
                <div class="kt-widget1__info">
                  <h3 class="kt-widget1__title">{{ $t('PAGES.USERS.DONATION.LABEL.donation_times') }}</h3>
                  <span class="kt-widget1__desc">{{ $t('PAGES.USERS.DONATION.LABEL.donation_times_desc') }}</span>
                </div>
                <span class="kt-widget1__number kt-font-success">{{ user.donation_times }} <span>{{ $t('PAGES.USERS.DONATION.LABEL.times') }}</span></span>
              </div>
              <div class="kt-widget1__item">
                <div class="kt-widget1__info">
                  <h3 class="kt-widget1__title">{{ $t('PAGES.USERS.DONATION.LABEL.order_total_fee_avg') }}</h3>
                  <span class="kt-widget1__desc">{{ $t('PAGES.USERS.DONATION.LABEL.order_total_fee_avg_desc') }}</span>
                </div>
                <span class="kt-widget1__number kt-font-warning">{{ user.order_total_fee_avg }} <span>{{ $t('COMMON.$')}}</span></span>
              </div>
            </div>
          </template>
        </portlet>
      </b-col>
      <b-col sm="12" md="9">
        <b-overlay :show="donationsBusy">
          <b-row>
            <b-col class="d-flex" sm="12" :md="md" v-for="donation in donations" :key="donation.id">
              <portlet fluid-height body-fluid title=" " head-no-border head-size="sm" head-class="px-3" body-class="px-3 pt-0 pb-2">
                <template v-slot:body>
                  <div class="kt-widget-item-container">
                    <div class="kt-widget kt-widget--user-profile-2">
                      <div class="kt-widget__head">
                        <div class="kt-widget__media">
                          <b-avatar class="kt-widget__img" rounded size="60" text="!" :src="donation.article && donation.article.cover_image_preview" />
                        </div>
                        <div class="kt-widget__info text-justify">
                          <a class="kt-widget__username">
                            {{ getTitle(donation) }}
                          </a>
                        </div>
                      </div>
                      <div class="kt-widget__body">
                        <div class="kt-widget__item">
                          <div class="kt-widget__contact cursor-default">
                            <span class="kt-widget__label">{{ $t('PAGES.USERS.DONATION.LABEL.user_total_fee') }}</span>
                            <span class="kt-widget__data">{{ donation.user_total_fee }} {{ $t("COMMON.$") }}</span>
                          </div>
                          <div class="kt-widget__contact cursor-default">
                            <span class="kt-widget__label">{{ $t('PAGES.USERS.DONATION.LABEL.total_times') }}</span>
                            <span class="kt-widget__data">{{ donation.total_times }} {{ $t('PAGES.USERS.DONATION.LABEL.times') }}</span>
                          </div>
                          <div class="kt-widget__contact cursor-default" v-if="t">
                            <span class="kt-widget__label">{{ $t('PAGES.USERS.DONATION.LABEL.total_times') }}</span>
                            <span class="kt-widget__data">{{ donation.total_times }} {{ $t('PAGES.USERS.DONATION.LABEL.times') }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </portlet>
            </b-col>
          </b-row>
        </b-overlay>
      </b-col>
    </b-row>
    <paginate :implement-search="false" :meta="donationsMeta" @refresh="onRefresh" @change-page="onChangePage" />
  </div>
</template>

<script>
  import Portlet from "../../../partials/content/Portlet";
  import Error from "../../../partials/widgets/Error";
  import paginate from "@v@/components/paginate";
  import BreadCrumbs from "@c@/breadcrumbs.service";
  import sweetMixin from "@m@/common/sweetAlert.mixin";
  import userMixin from "@m@/user.mixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import 'vue-datetime/dist/vue-datetime.css'
  import { Datetime } from "vue-datetime";
  import { mapGetters } from "vuex";

  export default {
    name: "index",
    components: { Error, Portlet, Datetime, paginate },
    mixins: [ sweetMixin, userMixin, formBusyMixin ],
    computed: {
      ...mapGetters(['donations', 'donationsMeta']),
      userId(){
        let {id} = this.$route.params;
        return id;
      },
      lang(){
        const { locale } = this.$i18n;
        return locale;
      },
      md(){
        const { donations } = this;
        if( donations.length === 1 ){
          return 12
        }else if( donations.length === 2 ){
          return 6
        }else if( donations.length >= 3 ){
          return 4
        }else {
          return 4
        }
      }
    },
    created() {
      this.getUser(this.userId, {
        begin: this.showBusy('showUserBusy'),
        after: this.hideBusy('showUserBusy'),
      });
      this.getUserDonations(this.userId)
    },
    mounted() {
      BreadCrumbs.setBreadCrumb([]);
      BreadCrumbs.addBreadCrumb({title: this.$t('PAGES.USERS.DONATION.BREAD_CRUMBS_TITLES.FIRST_TITLE'), route: {name: 'user.home'}});
      BreadCrumbs.addBreadCrumb({ title: this.$t('PAGES.USERS.DONATION.BREAD_CRUMBS_TITLES.CURRENT_TITLE') });
    },
    data(){
      return {
        formBusy: false,
        showUserBusy: false,
        donationsBusy: false,
        t: false
      };
    },
    methods: {
      onRefresh(e){
        this.getUserDonations(this.userId)
      },
      onChangePage(e){
        const { page } = e;
        this.getUserDonations(this.userId, page)
      },
      getTitle(donation = {}){
        const { article: { subject: { title = {} } } } = donation || {};
        return title && title.hasOwnProperty(this.lang) ? title[this.lang].title : '';
      },
    }
  }
</script>

<style lang="scss">

  .kt-widget__icon {
    i {
      font-weight: 900 !important;
    }
  }

  .article-section {
    margin-inline-start: unset !important;
    margin-right: -20px;
    margin-left: -20px;
  }

</style>
