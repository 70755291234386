<template>
  <base-modal :id="id" :title="title || $t('COMPONENTS.MODALS.USER.SWITCH_LOCK.TITLE')" :modal-busy="busy" :size="size" v-model="modalState" >
    <b-row>
      <b-col>
        <b-form-group :label="label">
          <b-input-group>
            <b-input :placeholder="placeholder" v-model="formData.reason" :disabled="lockState" />
            <b-input-group-append>
              <b-button size="sm" @click="onClickSend" text="Button" variant="success">{{ $t('COMPONENTS.MODALS.USER.SWITCH_LOCK.BUTTON.SUBMIT') }}</b-button>
            </b-input-group-append>
            <b-form-invalid-feedback id="reason-live-feedback"></b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
  </base-modal>
</template>

<script>
  import baseModal from "./base.modal";
  import userMixin from "@m@/user.mixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  export default {
    name: "userSwitchLockModal",
    components: { baseModal },
    mixins: [ userMixin, formBusyMixin ],
    props: {
      id: {
        type: String,
        default: () => 'time-fragment-modal'
      },
      value: {
        type: Boolean,
      },
      title: {
        type: String,
      },
      busy: Boolean,
      size: {
        type: String,
        default: ()=> 'lg'
      },
      lockState: Boolean,
      userId: {
        type: [Number, String]
      }
    },
    computed: {
      modalState: {
        get(){
          return this.value;
        },
        set(v){
          this.$emit('input', v);
        },
      },
    },
    watch: {
      lockState: {
        handler(val, oldVal){
          if ( val ){
            this.placeholder = this.$t('COMPONENTS.MODALS.USER.SWITCH_LOCK.LABEL.OUT_REASON_TIPS');
            this.label = this.$t('COMPONENTS.MODALS.USER.SWITCH_LOCK.LABEL.OUT_REASON_LABEL');
          }else if( ! val ){
            this.placeholder = this.$t('COMPONENTS.MODALS.USER.SWITCH_LOCK.LABEL.IN_REASON_TIPS');
            this.label = this.$t('COMPONENTS.MODALS.USER.SWITCH_LOCK.LABEL.IN_REASON_LABEL');
          }else {
            this.placeholder = this.$t('COMPONENTS.MODALS.USER.SWITCH_LOCK.LABEL.IN_REASON_TIPS');
            this.label = this.$t('COMPONENTS.MODALS.USER.SWITCH_LOCK.LABEL.IN_REASON_LABEL');
          }
        },
      }
    },
    mounted() {

    },
    data(){
      return {
        formData: {
          reason: null
        },
        placeholder: this.$t('COMPONENTS.MODALS.USER.SWITCH_LOCK.LABEL.IN_REASON_TIPS'),
        label: this.$t('COMPONENTS.MODALS.USER.SWITCH_LOCK.LABEL.IN_REASON_LABEL')
      };
    },
    methods: {
      onClickSend(){
        this.switchUserLockState(this.userId, this.formData.reason, {
          begin: ()=> { this.$emit('switching') }
        })
          .then(()=>{
            this.$emit('switched');
            this.$nextTick(()=>{
              this.formData.reason = null;
            });
          })
          .catch(()=>{
            this.$emit('failed')
          })
      },
    }
  }
</script>

<style scoped>

</style>
