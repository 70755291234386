<template>
  <div class="account-modal">
    <base-modal :id="id" :title="title || $t('COMPONENTS.MODALS.USER.ACCOUNT.TITLE')" :modal-busy="userBusy" :size="size" v-model="modalState" bg="bg-grey">
      <b-container fluid="" class="p-2">
        <b-row class="justify-content-center">
          <b-col sm="6" md="4" v-if="info.mini_program_account">
            <account :socialite-desc="$t('COMPONENTS.MODALS.USER.ACCOUNT.LABEL.DESC.miniprogram')" :info="info.mini_program_account || {}" />
          </b-col>
          <b-col sm="6" md="4" v-if="info.we_chat_account">
            <account :socialite-desc="$t('COMPONENTS.MODALS.USER.ACCOUNT.LABEL.DESC.officialAccount')" :info="info.we_chat_account || {}" />
          </b-col>
          <b-col sm="6" md="4" v-if="info.app_account">
            <account :socialite-desc="$t('COMPONENTS.MODALS.USER.ACCOUNT.LABEL.DESC.app')" :info="info.app_account || {}" />
          </b-col>
        </b-row>
        <b-button @click="onConfirm" size="sm" variant="primary">
          {{ $t('COMPONENTS.MODALS.USER.ACCOUNT.LABEL.DESC.miniprogram') }}
        </b-button>
      </b-container>
    </base-modal>
  </div>
</template>

<script>
  import baseModal from "@v@/components/modals/base.modal";
  import Account from "@v@/components/items/user/account";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import {mapGetters} from "vuex";
  export default {
    name: "UserAccountModal",
    props: {
      id: {
        type: String,
        default: () => 'user-account-modal'
      },
      value: {
        type: Boolean,
      },
      title: {
        type: String,
        default: () => 'ئەزا سالاھىيىتى'
      },
      size: {
        type: String,
        default: () => 'xl'
      },
      info: {
        type: Object,
        default: ()=>{ return {} }
      }
    },
    components: { baseModal, Account },
    mixins: [ formBusyMixin ],
    computed: {
      modalState: {
        get(){
          return this.value;
        },
        set(v){
          this.$emit('input', v);
        },
      },
      ...mapGetters([])
    },
    watch: {},
    data(){
      return {
        userBusy: false,
      };
    },
    methods: {
      onConfirm(){
        this.$emit('confirm');
      },
    }
  }
</script>

<style scoped>

</style>
